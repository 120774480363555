<template>
  <div class="container content">
    <div class="headline">
      <h1>{{ $t('message.education.show.header') }}</h1>
    </div>

    <b-modal v-model="dialogIsVisible"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal>
        <template>
            <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    {{ $t("message.education.confirm-enroll.header") }}
                </p>
                <button
                    type="button"
                    class="delete"
                    @click="confirmDialogClose"
                />
            </header>
            <section class="modal-card-body">
                <div><p>{{ $t("message.education.confirm-enroll.txt") }}</p></div>
                <p>&nbsp;</p>
                <b-button
                    :loading="false"
                  :label="$t('message.education.confirm-enroll.button')"
                  size="is-large"
                  class="fullwidth"
                  type="is-success" 
                  v-on:click="do_enroll" />
            </section>
        </div>
        </template>
    </b-modal>


    <div class="tile is-ancestor">
      <div class="tile is-parent is-4">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.education.show.instructions.header') }}</p>
          <div v-html="$t('message.education.show.instructions.txt')"></div>
        </article>
     </div>
      <div class="tile is-parent is-8">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.education.show.header') }}</p>
          <div class="columns">
           <div class="column is-full">
             <b-field label="" v-if="education.img_url !== null">
                <img :src="education.img_url">
              </b-field>
               <b-field :label="$t('message.education.show.name')">
                 {{ education.name }}
              </b-field>
              <b-field :label="$t('message.education.show.description')">
                {{ education.description }}
              </b-field>

              <b-field :label="$t('message.education.show.link')">
                {{ education.link }}
              </b-field>

              <div class="columns" v-if="education.type == 'koulutus'">
                <div class="column is-half">
                  <b-field :label="$t('message.education.show.price')">
                    {{ education.price }}
                  </b-field>
                </div>
                <div class="column is-half">
                  <b-field :label="$t('message.education.show.pax-limit')">
                      {{ education.pax_limit }}
                  </b-field>
                </div>
              </div>
              
              <div class="columns" v-if="education.type == 'koulutus'">
                <div class="column is-half">
                  <section>
                    <b-field :label="$t('message.education.show.register-start')">
                      {{ formatDate(education.register_start) }}
                    </b-field>
                  </section>
                </div>

                <div class="column is-half">
                  <section>
                    <b-field :label="$t('message.education.show.register-end')">
                      {{ formatDate(education.register_end) }}
                    </b-field>
                  </section>
                </div>
              </div>

              <div class="columns" v-if="education.type == 'koulutus'">
                <div class="column is-half">
                  <section>
                    <b-field :label="$t('message.education.show.date-start')">
                      {{ formatDate(education.course_start) }}
                    </b-field>
                  </section>
                </div>
                <div class="column is-half">
                <section>
                  <b-field :label="$t('message.education.show.date-end')">
                    {{ formatDate(education.course_end) }}
                  </b-field>
                </section>
                </div>
              </div>

             </div>
            </div>

            <b-button v-if="education.type == 'koulutus' && !user"
              :loading="false"
              :label="$t('message.education.enroll.button-login-first')"
              size="is-large"
              class="fullwidth"
              type="is-light" />

            <b-button v-if="education.type == 'koulutus' && user && user.type != 'education_partner' && !enrollments.map(enrollment => enrollment.course_id).includes(education.id)"
              :loading="false"
              :label="$t('message.education.enroll.button')"
              size="is-large"
              class="fullwidth"
              type="is-success" 
              v-on:click="enroll" />

            <b-button v-if="education.type == 'koulutus' && user && user.type != 'education_partner' && enrollments.map(enrollment => enrollment.course_id).includes(education.id)"
              :loading="false"
              :label="$t('message.education.unenroll.button')"
              size="is-large"
              class="fullwidth"
              type="is-danger" 
              v-on:click="unenroll" />
        </article>
     </div>
    </div>  
  </div>
</template>

<script>
export default {
    data() {
        return {
            dialogIsVisible: false,
        }
    },
    methods: {
        formatDate(_date) {
          return this.$moment(_date, 'YYYY-MM-DD hh:mm:ss').format('DD.MM.YYYY');
        },

        enroll() {
            this.confirmDialogOpen()
        },

        confirmDialogOpen() {
            this.dialogIsVisible = true
        },

        confirmDialogClose() {
            this.dialogIsVisible = false
        },
        do_enroll() {
          console.log("enroll...")

          var enrollment = {
            course_id: this.education.id
          }

          this.$store.dispatch("enroll", enrollment).then((response) => {
                var status = response.status
                if (status >= 200 && status < 300) {
                    this.$router.push("/koulutus-kiitos/" + this.education.id)
                } else {
                    this.toastFailure(this.$t('message.education.enroll.error'));
                }
            }).catch((error) => {
                this.ERROR(error);
                this.toastFailure(this.$t('message.education.enroll.error'));
            });
        },
        unenroll() {
          this.$store.dispatch("unenroll", this.education).then((response) => {
                var status = response.status
                if (status >= 200 && status < 300) {
                    this.$router.push("/koulutukset")
                } else {
                    this.toastFailure(this.$t('message.education.unenroll.error'));
                }
            }).catch((error) => {
                this.ERROR(error);
                this.toastFailure(this.$t('message.education.unenroll.error'));
            });
        }
    },
    computed: {
        user() {
            return this.$store.getters['getUser']
        }, 
        education() {
          var __id = parseInt(this.$route.params.education_id, 10)
          var educations = this.$store.getters['getCourses']
          var education = educations.find(ed => ed.id == __id)
          return education
        },
        enrollments() {
          return this.$store.getters['getEnrollments']
        },
    },
    mounted() {
       this.$store.dispatch("fetchEducations");
       this.$store.dispatch("fetchEnrollments");
    }
}
</script>